import React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../../components/layout/layout";

import FilterBar from "../../components/browser/filter-bar";
import PageContainer from "../../components/layout/page-container";
import PageSection from "../../components/layout/page-section";

class ImagesByVenuePage extends React.Component {
  constructor({ data }) {
    super();

    this.data = data;
  }

  render() {
    const allImageEdges = this.data.allImagesJson.edges;

    let venueEdgesWithImages = this.data.allVenuesJson.edges.filter(({ node }) => {
      const venueReference = node.reference;

      const imagesForVenue = allImageEdges.filter(({ node }) => node.venues.includes(venueReference));
      
      return imagesForVenue.length > 0;
    });

    return (
      <Layout>
        <PageContainer
          bottomPadding="lg"
        >
          <PageSection background="dark-accent">
            <h1 className="page-title">Images by Venue</h1>
          </PageSection>
          <PageSection topPadding="md">
            <FilterBar filterSet="images" currentFilter="venue" />
            <ul className="listing-grid">
              {venueEdgesWithImages.map(({ node }) => (
                <li key={node.reference} className="listing-grid__cell">
                  <Link to={`/images/venue/${node.reference}`}>{node.canonical_name}</Link>
                </li>
              ))}
            </ul>
            <FilterBar filterSet="images" currentFilter="venue" />
          </PageSection>
        </PageContainer>
      </Layout>
    );
  }
}

export default ImagesByVenuePage;

export const query = graphql`
  query ImagesByVenueQuery {
    allVenuesJson(sort: {fields: canonical_name}) {
      edges {
        node {
          reference
          canonical_name
        }
      }
    }
    allImagesJson {
      edges {
        node {
          venues
        }
      }
    }
  }
`