import React from "react";
import { Link } from "gatsby";

function buildPerformancesFilter(filter) {
  let artistLabel = (<Link to="/performances/artists">Artist</Link>);
  let venueLabel = (<Link to="/performances/venues">Venue</Link>);
  let yearLabel = (<Link to="/performances/years">Year</Link>);

  switch (filter) {
    case 'artist':
      artistLabel = (<b>Artist</b>);
      break;
    case 'venue':
      venueLabel = (<b>Venue</b>);
      break;
    case 'year':
    default:
      yearLabel = (<b>Year</b>);
      break;
  }

  return (<span>Browse Performances by {artistLabel}, {venueLabel}, or {yearLabel}.</span>);
}

function buildImagesFilter(filter) {
  let artistLabel = (<Link to="/images/artists">Artist</Link>);
  let venueLabel = (<Link to="/images/venues">Venue</Link>);
  let yearLabel = (<Link to="/images/years">Year</Link>);

  switch (filter) {
    case 'artist':
      artistLabel = (<b>Artist</b>);
      break;
    case 'venue':
      venueLabel = (<b>Venue</b>);
      break;
    case 'year':
    default:
      yearLabel = (<b>Year</b>);
      break;
  }

  return (<span>Browse Images by {artistLabel}, {venueLabel}, or {yearLabel}.</span>);
}

export default (props) => {
  let contents = '';

  switch (props.filterSet) {
    case 'images':
      contents = buildImagesFilter(props.currentFilter);
      break;
    case 'performances':
    default:
      contents = buildPerformancesFilter(props.currentFilter);
      break;
  }

  return (
    <div className="filter-bar">
      <p className="filter-bar__contents">
        {contents}
      </p>
    </div>
  );
}
