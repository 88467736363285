import React from "react";
import { Link, StaticQuery, graphql } from "gatsby";

import HelmetWrapper from "./helmet";
import { HamburgerSvg } from "../common/Svgs";
import Footer from "./footer";

import '../../scss/styles.scss';

const mobileNavRef = React.createRef();
const hamburgerRef = React.createRef();

export default ({ children }) => (
  <StaticQuery
    query={graphql`
      query DefaultLayoutQuery {
        site {
          siteMetadata {
            title
            short_title
            subtitle
            description
          }
        }
      }
    `}
    render={data => (
      <div>
        <HelmetWrapper site={data.site} />
        <div className="page">
          <div className="header-wrapper">
            <header className="general">
              <div className="header-contents wrapper-width">
                <div className="title-block">
                  <Link className="link" to="/">{data.site.siteMetadata.short_title}: <span className="subtitle">{data.site.siteMetadata.subtitle}</span></Link>
                </div>
                <nav className="header-nav">
                  <ul>
                    <li><Link to="/notes">Blog</Link></li>
                    <li><Link to="/about">About</Link></li>
                  </ul>
                </nav>
                <button
                  className="mobile-nav-launcher"
                  ref={ hamburgerRef }
                  onClick={(e) => {
                    const mobileNav = mobileNavRef.current;
                    const hamburgerButton = hamburgerRef.current;

                    if (mobileNav.classList.contains('expanded')) {
                      hamburgerButton.classList.remove('expanded');
                      mobileNav.classList.remove('expanded');
                    } else {
                      hamburgerButton.classList.add('expanded');
                      mobileNav.classList.add('expanded');
                    }
                  }}
                >
                  { HamburgerSvg }
                </button>
              </div>
            </header>
            <div
              className="mobile-header"
              ref={ mobileNavRef }
            >
              <ul>
                <li><Link to="/notes">Blog</Link></li>
                <li><Link to="/about">About</Link></li>
              </ul>
            </div>
          </div>
          {children}
        </div>
        <Footer site={data.site} />
      </div>
    )}
  />
)
